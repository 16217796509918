import { render, staticRenderFns } from "./CategorySelect.vue?vue&type=template&id=0e975681&scoped=true&lang=pug"
import script from "./CategorySelect.vue?vue&type=script&lang=js"
export * from "./CategorySelect.vue?vue&type=script&lang=js"
import style0 from "./CategorySelect.vue?vue&type=style&index=0&id=0e975681&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e975681",
  null
  
)

export default component.exports